<template>
  <router-view></router-view>
</template>
<script>
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router';
import { useTab } from '@/hooks/tabs/index';
export default {
  name: "error",
  setup(){
    
    onBeforeRouteLeave((to, from, next) =>{
      const {delVisitedRoute} = useTab();
      delVisitedRoute(from);
      next();
    });
    return {};
  }
}
</script>
